// import libraries
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Button, Dropdown, Form, Image, Modal, Spinner } from 'react-bootstrap';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import { FaFileUpload } from 'react-icons/fa';
import imageCompression from 'browser-image-compression';
import { useTranslation } from 'react-i18next';
// import utils
import { getDataID, postDataUser, putDataUser } from '../utils/api.js';
import { API_ROUTES, LOCAL_URL } from '../utils/constants.js';
import { validateEmail } from '../utils/utils.js';
// import assets
import defaultUser from '../assets/images/defaultUser.png';
// import css
import '../assets/styles/buttonModalAdmin.scss';

/*
Button Modal Component:
Displays a button showing a modal answering a yes-no question.

@params {icon} iconButton - The icon of button.
@params {string} nameButton - The text of button.
@params {string} variantButton - Variant of bootstrap button.
@params {string} modalTitle - Title of the modal.
@params {string} modalBody - Text of the modal.
@params {icon} iconConfirm - The icon of the valide button.
@params {string} nameConfirm - The text of the valide button.
@params {string} variantConfirm - Variant of valide button.
@params {function} handleConfirm - Function call on click valide button.
@params {icon} iconCancel - The icon of the cancel button.
@params {string} nameCancel - The text of the cancel button.
@params {string} variantCancel - Variant of cancel button.
@params {function} handleCancel - Function call on click cancel button.

*/

const initialUserState = {
  id: '',
  id_title: 1,
  firstname: '',
  lastname: '',
  email: '',
  phone: null,
  agency: null,
  network: null,
  role: null,
  login: '',
  password: '',
  confirm: '',
  features: [],
  imageURL: null,
  file: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, ...action.value };
    case 'UPDATE':
      return { ...state, [action.field]: action.value };
    case 'RESET':
      return initialUserState;
    default:
      return state;
  }
};

export default function ButtonModalAdmin({
  iconButton,
  nameButton,
  variantButton,
  data,
  size,
  className,
  updateData,
  setUpdateData,
  agencies,
  networks,
  features,
  roles,
  rolesByFeatures,
  title,
  tooltip,
  auth,
}) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);

  const handleClose = () => setShow(false);

  const [phone, setPhone] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const inputRef = useRef(null);
  const [user, dispatch] = useReducer(reducer, initialUserState);
  const { t } = useTranslation();
  const featureAdminUpdateUser = 'admin_update_user';

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        setLoading(false);
      } catch (error) {
        console.log('error on fetch data ', error);
      }
    };
    fetchData();
  }, [show]);

  const handleData = (id) => {
    const fetchData = async () => {
      try {
        const response = await getDataID(API_ROUTES.USERS.GET.UPDATE, id);
        dispatch({ type: 'SET_USER', value: response.data });
      } catch (error) {
        console.log('error on fetch data user', error);
      }
    };
    if (data) {
      fetchData();
    }
    setShow(true);
  };

  const handleChange = (field) => (e) => {
    dispatch({ type: 'UPDATE', field, value: e.target.value });
  };

  useEffect(() => {
    dispatch({ type: 'UPDATE', field: 'phone', value: phone });
  }, [phone]);

  const handleSelect = (eventKey, field) => {
    dispatch({ type: 'UPDATE', field, value: eventKey });
  };

  useEffect(() => {
    if (user.password.length > 0 || user.confirm.length > 0) {
      if (user.password === user.confirm) {
        setIsConfirmed(true);
      } else {
        setIsConfirmed(false);
      }
    }
  }, [user.password, user.confirm]);

  const handleFeatureChange = (feature) => (e) => {
    if (e.target.checked) {
      dispatch({
        type: 'UPDATE',
        field: 'features',
        value: [...user.features, feature],
      });
    } else {
      dispatch({
        type: 'UPDATE',
        field: 'features',
        value: user.features.filter((f) => f !== feature),
      });
    }
  };

  const handleRoleSelect = (roleId) => {
    const associatedFeatures = rolesByFeatures
      .filter((roleFeature) => roleFeature.id_role === roleId)
      .map((roleFeature) => roleFeature.id_feature);

    const featuresToSet = features
      .filter((feature) => associatedFeatures.includes(feature.id))
      .map((feature) => feature.id);

    dispatch({ type: 'UPDATE', field: 'features', value: featuresToSet });
    dispatch({ type: 'UPDATE', field: 'role', value: roleId });
  };

  const handleButton = () => {
    inputRef.current?.click();
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    if (file && file.type.startsWith('image')) {
      return imageCompression(file, options).then((compressedFile) => {
        dispatch({
          type: 'UPDATE',
          field: 'file',
          value: compressedFile,
        });
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    const formData = new FormData();
    Object.entries(user).forEach(([key, value]) => {
      if (key === 'features' && Array.isArray(value)) {
        value.forEach((feature, index) => {
          formData.append(`features[${index}]`, feature);
        });
      } else if (key === 'file' && value) {
        if (value instanceof File || value instanceof Blob) {
          formData.append('image', value);
        } else {
          console.log('The image is not a valid file:', value);
        }
      } else {
        formData.append(key, value);
      }
    });
    formData.append('locale', JSON.parse(localStorage.getItem('ids')).locale);

    let response;
    if (data) {
      response = await putDataUser(API_ROUTES.USERS.PUT.USER, formData);
    } else {
      response = await postDataUser(API_ROUTES.USERS.POST.USER, formData);
    }
    if (response.status === 201 || response.status === 200) {
      handleClose();
      dispatch({ type: 'RESET' });
      setPhone('');
      setShowPassword(false);
      setUpdateData(!updateData);
      setSending(false);
    }
  };

  const handleReset = () => {
    handleClose();
    setPhone('');
    setShowPassword(false);
    dispatch({ type: 'RESET' });
  };

  return (
    <React.Fragment>
      <Button
        variant={variantButton}
        size={size}
        onClick={() => handleData(data?.id)}
        className={className}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={tooltip}
      >
        {iconButton ? (
          <React.Fragment>
            {iconButton}
            <span>{nameButton}</span>
          </React.Fragment>
        ) : (
          nameButton
        )}
      </Button>

      <Modal show={show} onHide={handleReset} className="modal">
        <Modal.Header closeButton>
          <Modal.Title>
            {data
              ? `${t('components.buttonUserModalAdmin.update')} ${
                  data.username
                } | ${data.id}`
              : `${t('components.buttonUserModalAdmin.createNew')}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal__body">
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <React.Fragment>
              <Form
                id="form__adding"
                onSubmit={(e) => handleSubmit(e)}
                onReset={handleReset}
                className="modal__body__form"
              >
                <Form.Group className="modal__body__form__group">
                  <input
                    ref={inputRef}
                    onChange={(e) => handleImage(e)}
                    type="file"
                    accept=".jpeg,.jpg,.png"
                    name="file"
                    hidden
                  />
                  <Button
                    onClick={handleButton}
                    className="modal__body__form__group__imageButton"
                  >
                    <Image
                      src={
                        user.file
                          ? URL.createObjectURL(user.file)
                          : user.imageURL
                          ? `${LOCAL_URL}/files/profile/${user.imageURL}`
                          : defaultUser
                      }
                      roundedCircle
                      className="modal__body__form__group__imageButton__image"
                    />
                    <div className="modal__body__form__group__imageButton--hover">
                      <FaFileUpload />
                      <span>
                        {t('components.buttonUserModalAdmin.addImage')}
                      </span>
                    </div>
                  </Button>
                  <div className="modal__body__form">
                    <Form.Group className="modal__body__form__group">
                      <Form.Select
                        name="title"
                        value={user.id_title}
                        onChange={handleChange('id_title')}
                      >
                        {title &&
                          title.map((element, index) => {
                            return (
                              <option key={index} value={element.id}>
                                {element.value}
                              </option>
                            );
                          })}
                      </Form.Select>
                      <Form.Control
                        type="text"
                        placeholder={t(
                          'components.buttonUserModalAdmin.firstname',
                        )}
                        value={user.firstname}
                        onChange={handleChange('firstname')}
                      />
                      <Form.Control
                        type="text"
                        placeholder={t(
                          'components.buttonUserModalAdmin.lastname',
                        )}
                        value={user.lastname}
                        onChange={handleChange('lastname')}
                      />
                    </Form.Group>
                    <Form.Group className="modal__body__form__group">
                      <Form.Control
                        type="email"
                        placeholder={t('components.buttonUserModalAdmin.email')}
                        value={user.email}
                        onChange={handleChange('email')}
                      />
                      <PhoneInput
                        placeholder={t('components.buttonUserModalAdmin.phone')}
                        name="phone"
                        defaultCountry="FR"
                        value={user?.phone}
                        onChange={(value) => setPhone(value || '')}
                        inputComponent={Form.Control}
                        className="w-75"
                      />
                    </Form.Group>
                    <Form.Group className="modal__body__form__group">
                      <Dropdown
                        onSelect={(eventKey, e) =>
                          handleSelect(eventKey, 'network')
                        }
                        className="modal__body__form__group__dropdown"
                      >
                        <Dropdown.Toggle id="dropdown-network">
                          {user.network
                            ? networks.filter(
                                (item) => item.id === Number(user.network),
                              )[0].value
                            : `${t(
                                'components.buttonUserModalAdmin.selectNetwork',
                              )}`}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="modal__body__form__group__dropdown__menu">
                          {networks &&
                            networks.map((item) => {
                              return (
                                <Dropdown.Item key={item.id} eventKey={item.id}>
                                  {item.value}
                                </Dropdown.Item>
                              );
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown
                        onSelect={(eventKey, e) =>
                          handleSelect(eventKey, 'agency')
                        }
                        className="modal__body__form__group__dropdown"
                      >
                        <Dropdown.Toggle id="dropdown-agency">
                          {user.agency
                            ? agencies.filter(
                                (item) => item.id === Number(user.agency),
                              )[0].town
                            : `${t(
                                'components.buttonUserModalAdmin.selectAgency',
                              )}`}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="modal__body__form__group__dropdown__menu">
                          {agencies &&
                            agencies.map((item) => {
                              return (
                                <Dropdown.Item key={item.id} eventKey={item.id}>
                                  {item.town}
                                </Dropdown.Item>
                              );
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                  </div>
                </Form.Group>
                <Form.Group className="modal__body__form__group">
                  <Form.Control
                    type="text"
                    placeholder={t('components.buttonUserModalAdmin.login')}
                    value={user.login}
                    onChange={handleChange('login')}
                  />
                  {(!data ||
                    (data && auth.includes(featureAdminUpdateUser))) && (
                    <React.Fragment>
                      <div className="modal__body__form__group__password">
                        <Form.Control
                          type={showPassword ? 'text' : 'password'}
                          placeholder={t(
                            'components.buttonUserModalAdmin.password',
                          )}
                          onChange={handleChange('password')}
                          className="modal__body__form__group__password__input"
                        />
                        <Button
                          onClick={() => setShowPassword(!showPassword)}
                          className="modal__body__form__group__password__button"
                        >
                          {showPassword ? (
                            <FaRegEyeSlash className="modal__body__form__group__password__button__icon" />
                          ) : (
                            <FaRegEye className="modal__body__form__group__password__button__icon" />
                          )}
                        </Button>
                      </div>
                      <div className="modal__body__form__group__password">
                        <Form.Control
                          type={showPassword ? 'text' : 'password'}
                          placeholder={t(
                            'components.buttonUserModalAdmin.confirmPassword',
                          )}
                          onChange={handleChange('confirm')}
                          className="modal__body__form__group__password__input"
                        />
                        <Button
                          onClick={() => setShowPassword(!showPassword)}
                          className="modal__body__form__group__password__button"
                        >
                          {showPassword ? (
                            <FaRegEyeSlash className="modal__body__form__group__password__button__icon" />
                          ) : (
                            <FaRegEye className="modal__body__form__group__password__button__icon" />
                          )}
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                </Form.Group>
              </Form>
              <hr />
              <div className="modal__body__permissions">
                <span className="modal__body__permissions__title">
                  {t('components.buttonUserModalAdmin.permissions')}
                </span>
                <Dropdown>
                  <Dropdown.Toggle variant="info" id="dropdown-role">
                    {user.role
                      ? roles.filter((item) => item.id === Number(user.role))[0]
                          .value
                      : `${t('components.buttonUserModalAdmin.selectRole')}`}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {roles &&
                      roles.map((item, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            eventKey={item.value}
                            onClick={() => handleRoleSelect(item.id)}
                          >
                            {item.value}
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <hr />
              <div>
                <div className="modal__body__permissions__group">
                  <span>{t('components.buttonUserModalAdmin.dashboard')}</span>
                  <ul className="modal__body__permissions__group__list">
                    {features &&
                      features
                        .filter((item) => item.value.startsWith('dashboard'))
                        .map((item, index) => {
                          return (
                            <li
                              key={index}
                              className="modal__body__permissions__group__list__item"
                            >
                              <Form.Check
                                type="checkbox"
                                label={item.value}
                                onChange={handleFeatureChange(item.id)}
                                checked={user.features.includes(item.id)}
                              />
                            </li>
                          );
                        })}
                  </ul>
                </div>
                <div className="modal__body__permissions__group">
                  <span>{t('components.buttonUserModalAdmin.boats')}</span>
                  <ul className="modal__body__permissions__group__list">
                    {features &&
                      features
                        .filter((item) => item.value.startsWith('boats'))
                        .map((item, index) => {
                          return (
                            <li
                              key={index}
                              className="modal__body__permissions__group__list__item"
                            >
                              <Form.Check
                                type="checkbox"
                                label={item.value}
                                onChange={handleFeatureChange(item.id)}
                                checked={user.features.includes(item.id)}
                              />
                            </li>
                          );
                        })}
                  </ul>
                </div>
                <div className="modal__body__permissions__group">
                  <span>{t('components.buttonUserModalAdmin.boat')}</span>
                  <ul className="modal__body__permissions__group__list">
                    {features &&
                      features
                        .filter((item) => item.value.startsWith('boat_'))
                        .map((item, index) => {
                          return (
                            <li
                              key={index}
                              className="modal__body__permissions__group__list__item"
                            >
                              <Form.Check
                                type="checkbox"
                                label={item.value}
                                onChange={handleFeatureChange(item.id)}
                                checked={user.features.includes(item.id)}
                              />
                            </li>
                          );
                        })}
                  </ul>
                </div>
                <div className="modal__body__permissions__group">
                  <span>{t('components.buttonUserModalAdmin.person')}</span>
                  <ul className="modal__body__permissions__group__list">
                    {features &&
                      features
                        .filter((item) => item.value.startsWith('person'))
                        .map((item, index) => {
                          return (
                            <li
                              key={index}
                              className="modal__body__permissions__group__list__item"
                            >
                              <Form.Check
                                type="checkbox"
                                label={item.value}
                                onChange={handleFeatureChange(item.id)}
                                checked={user.features.includes(item.id)}
                              />
                            </li>
                          );
                        })}
                  </ul>
                </div>
                <div className="modal__body__permissions__group">
                  <span>
                    {t('components.buttonUserModalAdmin.transactions')}
                  </span>
                  <ul className="modal__body__permissions__group__list">
                    {features &&
                      features
                        .filter((item) => item.value.startsWith('transactions'))
                        .map((item, index) => {
                          return (
                            <li
                              key={index}
                              className="modal__body__permissions__group__list__item"
                            >
                              <Form.Check
                                type="checkbox"
                                label={item.value}
                                onChange={handleFeatureChange(item.id)}
                                checked={user.features.includes(item.id)}
                              />
                            </li>
                          );
                        })}
                  </ul>
                </div>
                <div className="modal__body__permissions__group">
                  <span>
                    {t('components.buttonUserModalAdmin.transaction')}
                  </span>
                  <ul className="modal__body__permissions__group__list">
                    {features &&
                      features
                        .filter((item) => item.value.startsWith('transaction_'))
                        .map((item, index) => {
                          return (
                            <li
                              key={index}
                              className="modal__body__permissions__group__list__item"
                            >
                              <Form.Check
                                type="checkbox"
                                label={item.value}
                                onChange={handleFeatureChange(item.id)}
                                checked={user.features.includes(item.id)}
                              />
                            </li>
                          );
                        })}
                  </ul>
                </div>
                <div className="modal__body__permissions__group">
                  <span>{t('components.buttonUserModalAdmin.admin')}</span>
                  <ul className="modal__body__permissions__group__list">
                    {features &&
                      features
                        .filter((item) => item.value.startsWith('admin'))
                        .map((item, index) => {
                          return (
                            <li
                              key={index}
                              className="modal__body__permissions__group__list__item"
                            >
                              <Form.Check
                                type="checkbox"
                                label={item.value}
                                onChange={handleFeatureChange(item.id)}
                                checked={user.features.includes(item.id)}
                              />
                            </li>
                          );
                        })}
                  </ul>
                </div>
              </div>
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleReset}>
            {t('components.buttonUserModalAdmin.cancel')}
          </Button>
          <Button
            variant="success"
            type="submit"
            form="form__adding"
            disabled={
              sending ||
              user.firstname.length === 0 ||
              user.lastname.length === 0 ||
              !validateEmail(user.email) ||
              !isValidPhoneNumber(user.phone) ||
              user.agency === null ||
              user.network === null ||
              user.login.length === 0 ||
              (user.password.length !== 0
                ? (!data || (data && auth.includes(featureAdminUpdateUser))) &&
                  !isConfirmed
                : false) ||
              !user.role
            }
            onClick={handleSubmit}
          >
            {sending ? (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                size="sm"
              ></Spinner>
            ) : (
              t('components.buttonUserModalAdmin.validate')
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
