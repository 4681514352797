// import libraries
import React, { useEffect, useState } from 'react';
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import {
  FaHouse,
  FaSailboat,
  FaUser,
  FaGear,
  FaMoneyBillTransfer,
} from 'react-icons/fa6';
import { FaPrint, FaSignOutAlt, FaUserShield } from 'react-icons/fa';
import { IoMdAddCircle, IoMdDocument } from 'react-icons/io';
import { BsFillGridFill } from 'react-icons/bs';
import { TbFileTypeXml } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
// import utils
import { getData } from '../utils/api';
import { API_ROUTES } from '../utils/constants';
// import css
import '../assets/styles/sidebar.scss';

/*
Left sidebar component
Navigation menu with links to other pages.
Display only when user is logged-in.
*/

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: boatId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [menu, setMenu] = useState('dash');
  const [stateDisplay, setStateDisplay] = useState('');
  const agencyId = (() => {
    try {
      const storedIds = localStorage.getItem('ids');
      if (storedIds) {
        const parsedIds = JSON.parse(storedIds);
        return parsedIds?.id_agency ?? null;
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération de l'ID de l'agence :",
        error
      );
    }
    return null;
  })();
  // features
  const featureAdminDashboard = 'admin_dashboard';
  const featureBoatUpdateSub = 'boat_update_sub';
  const featureXML = 'sadmin_xml';
  const [auth, setAuth] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    // Récupérer les autorisations utilisateur
    const fetchAuthoriz = async () => {
      try {
        const response = await getData(API_ROUTES.USERS.GET.AUTHORIZATION);
        setAuth(response.data);
      } catch (error) {
        console.log('error on fetch auth ', error);
      }
    };
    fetchAuthoriz();
  }, []);

  useEffect(() => {
    const admin = document.getElementById('admin');

    if (location.pathname.startsWith('/boats')) {
      setStateDisplay('boat');
    } else if (admin && admin.classList.contains('active')) {
      setStateDisplay('admin');
    } else {
      setStateDisplay('');
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  // Gestion de la déconnexion
  const logout = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      localStorage.removeItem('ids');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      navigate('/login');
      setLoading(false);
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <>
      <header className="sidebar">
        <ul className="sidebar__wrapper">
          <li>
            <NavLink
              to="/"
              onClick={() => setMenu('dash')}
              className="sidebar__link"
            >
              <FaHouse />
              <span className="sidebar__link__label">
                {t('components.sidebar.dashboard')}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/boats"
              id="boat"
              onClick={() => setMenu('boat')}
              className="sidebar__link"
            >
              <BsFillGridFill />
              <span className="sidebar__link__label">
                {t('components.sidebar.boats')}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/people"
              id="people"
              onClick={() => setMenu('people')}
              className="sidebar__link"
            >
              <FaUser />
              <span className="sidebar__link__label">
                {t('components.sidebar.people')}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/transactions"
              id="transactions"
              onClick={() => setMenu('transactions')}
              className="sidebar__link"
            >
              <FaMoneyBillTransfer />
              <span className="sidebar__link__label">
                {t('components.sidebar.transactions')}
              </span>
            </NavLink>
          </li>
          {auth.includes(featureAdminDashboard) && (
            <li>
              <NavLink
                to="/admin"
                id="admin"
                onClick={() => setMenu('admin')}
                className="sidebar__link"
              >
                <FaUserShield />
                <span className="sidebar__link__label">
                  {t('components.sidebar.administration')}
                </span>
              </NavLink>
            </li>
          )}
          <hr className="sidebar__hr" />
          {stateDisplay === 'boat' && (
            <li>
              <NavLink to="/boats/create" className="sidebar__link">
                <IoMdAddCircle />
                <span className="sidebar__link__label">
                  {t('components.sidebar.create')}
                </span>
              </NavLink>
            </li>
          )}
          {stateDisplay === 'boat' && boatId && (
            <li>
              <NavLink
                to={`/boats/${boatId}`}
                className={({ isActive }) =>
                  `sidebar__link ${isActive ? 'active' : ''}`
                }
                end // Cette propriété "end" assure que l'active n'est appliqué que pour le chemin exact
              >
                <FaSailboat />
                <span className="sidebar__link__label">
                  {t('components.sidebar.boat')}
                </span>
              </NavLink>
            </li>
          )}
          {stateDisplay === 'boat' &&
            auth.includes(featureBoatUpdateSub) &&
            boatId && (
              <li>
                <NavLink
                  to={`/boats/${boatId}/documents`}
                  className={({ isActive }) =>
                    `sidebar__link ${isActive ? 'active' : ''}`
                  }
                  end // Cette propriété "end" assure que l'active n'est appliqué que pour le chemin exact
                >
                  <IoMdDocument />
                  <span className="sidebar__link__label">
                    {t('components.sidebar.documents')}
                  </span>
                </NavLink>
              </li>
            )}
          {stateDisplay === 'admin' && auth.includes(featureXML) && (
            <li>
              <NavLink to="/xml" className="sidebar__link">
                <TbFileTypeXml />
                <span className="sidebar__link__label">XML</span>
              </NavLink>
            </li>
          )}
          {stateDisplay === 'boat' && boatId && (
            <li>
              <NavLink
                to={`${API_ROUTES.BOATS.GET.PRINT}?boatId=${boatId}&agencyId=${agencyId}`}
                className={({ isActive }) =>
                  `sidebar__link ${isActive ? 'active' : ''}`
                }
                target="_blank"
                reloadDocument
                end // Cette propriété "end" assure que l'active n'est appliqué que pour le chemin exact
              >
                <FaPrint />
                <span className="sidebar__link__label">
                  {t('components.sidebar.printVitrine')}
                </span>
              </NavLink>
            </li>
          )}
        </ul>
        <ul className="sidebar__wrapper">
          <li>
            <NavLink to="/settings" className="sidebar__link">
              <FaGear />
              <span className="sidebar__link__label">
                {t('components.sidebar.settings')}
              </span>
            </NavLink>
          </li>
          <li>
            <Button className="sidebar__link logoutButton" onClick={logout}>
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              ) : (
                <FaSignOutAlt />
              )}
              <span className="sidebar__link__label">
                {t('components.sidebar.logout')}
              </span>
            </Button>
          </li>
        </ul>
      </header>
      <Outlet />
    </>
  );
}
// Export to call it up in app.jsx
export default Sidebar;
